<template>
  <div
    class="container"
    v-title
    id="anInSchedule"
    :data-title="$t('i18n.anInSchedule')"
  >
    <div id="outer-title">{{ $t("i18n.anInSchedule") }}</div>
    <el-input
      class="search-input"
      v-model="projectName"
      @change="initData"
      :placeholder="$t('i18n.projectName')"
    ></el-input>
    <el-select
      filterable
      clearable
      v-model="provinceIndex"
      @change="changeCityData"
      :placeholder="$t('i18n.province')"
    >
      <el-option
        v-for="(item, index) in provinceData"
        :key="index"
        :label="item.label"
        :value="index"
      ></el-option>
    </el-select>
    <el-select
      filterable
      clearable
      class="city"
      v-model="city"
      @change="initData"
      :placeholder="$t('i18n.city')"
    >
      <el-option
        v-for="(item, index) in cityData"
        :key="index"
        :label="item.label"
        :value="item.label"
      ></el-option>
    </el-select>
    <div class="chartContain" v-loading="loading">
      <jl-chart
        name="option"
        class="chartCircle"
        :columns="pieOption"
      ></jl-chart>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "AnnualInannualInspectionChart",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      projectName: "",
      province: "",
      city: "",
      provinceIndex: "",
      provinceData: proxy.$defined.getProvinceData(),
      cityData: [],
      loading: true,
      pieOption: {
        title: t("i18n.anInSchedule"),
        type: "pie",
        series: [
          {
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            radius: ["35%", "55%"],
            itemStyle: {
              normal: {
                label: {
                  formatter: "{b}:{d}%",
                },
              },
            },
            data: [],
          },
        ],
      },
    });

    const init = () => {
      initData();
    };

    const initData = async () => {
      let { data } = await proxy.$api.maintenance.getAnnualChart({
        projectName: state.projectName,
        province: state.province,
        city: state.city,
      });
      let arr = [];
      for (let key in data) {
        let obj = {
          name: key,
          value: data[key],
        };
        arr.push(obj);
      }
      state.pieOption.series[0].data = arr;
      state.loading = false;
    };

    const changeCityData = (index) => {
      state.city = "";
      state.cityData = state.provinceData[index].children;
      state.province = state.provinceData[index].label;
      initData();
    };

    init();

    return {
      ...toRefs(state),
      initData,
      changeCityData,
    };
  },
};
</script>

<style lang="scss">
#anInSchedule {
  .search-input {
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 45px;
    width: 300px;
  }
  .el-select {
    position: absolute;
    z-index: 2;
    top: 95px;
    left: 45px;
    width: 145px;
  }
  .city {
    left: 200px;
  }
}
@media screen and (max-width: 768px) {
  #anInSchedule {
    .search-input {
      position: relative;
      top: 0;
      left: 10px;
    }
    .el-select {
      position: relative;
      top: 0;
      left: 0;
      margin: 10px 0 0 10px;
    }
  }
}
</style>
